import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import PayoutPage from '../../../Component/IBDashBoard/PayoutPage';

const Payout = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="IB" title="Payout" />
      <PayoutPage />
    </Fragment>
  );
};
export default Payout;