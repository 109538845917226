import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Media, Table, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Dropdown,
  DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import { LuRefreshCcw } from "react-icons/lu";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import {
  getAllRoles,
  createRole,
  updateRole,
  deleteRole,
} from '../../Services/RoleService';
import { toast } from 'react-toastify';

const entities = [
  'student',
  'form',
  'batch',
  'ib',
  'settings',
  'studyMaterial',
  'course',
  "wapikon",
  "trigger",
  "log",
  "mailSetup",
  "formResponse"
];

const permissionOptions = ['create', 'read', 'update', 'delete'];

const OnGoing = () => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState({ permissions: new Map() });
  const [roles, setRoles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const data = await getAllRoles();
      setRoles(data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const toggleModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setAddModal(!addModal);
        setSelectedRole({ permissions: new Map() });
        break;
      case 'edit':
        setEditModal(!editModal);
        break;
      case 'delete':
        setDeleteModal(!deleteModal);
        break;
      default:
        break;
    }
  };

  const handleEditClick = (role) => {
    // Convert permissions object back to a Map for easier management
    const permissionsMap = role.permissions ? new Map(Object.entries(role.permissions)) : new Map();
    setSelectedRole({ ...role, permissions: permissionsMap });
    toggleModal('edit');
  };

  const handleDeleteClick = (role) => {
    setSelectedRole(role);
    toggleModal('delete');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedRole({ ...selectedRole, [name]: value });
  };

  const handlePermissionChange = (entity, permission) => {
    const currentPermissions = new Map(selectedRole.permissions);

    if (!currentPermissions.has(entity)) {
      currentPermissions.set(entity, { create: false, read: false, update: false, delete: false });
    }

    const entityPermissions = { ...currentPermissions.get(entity) };
    entityPermissions[permission] = !entityPermissions[permission];

    currentPermissions.set(entity, entityPermissions);
    setSelectedRole({ ...selectedRole, permissions: currentPermissions });
  };

  const handleSave = async () => {
    try {
      const roleToSave = {
        ...selectedRole,
        permissions: Object.fromEntries(selectedRole.permissions), // Convert Map to object before saving
      };

      if (selectedRole._id) {
        await updateRole(selectedRole._id, roleToSave);
      } else {
        if (!selectedRole.name || !selectedRole.email || !selectedRole.password) {
          toast('Please fill all required fields');
          return;
        }
        await createRole(roleToSave);
        toast.success("Role created successfully");
      }
      fetchRoles();
      toggleModal('add');
    } catch (error) {
      console.error('Error saving role:', error);
      toast.error('Error saving role');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRole(selectedRole._id);
      fetchRoles();
      toggleModal('delete');
      toast.success('Role deleted successfully');
    } catch (error) {
      console.error('Error deleting role:', error);
      toast.error('Error deleting role');
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRoles = roles.filter(role =>
    role.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    role.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Fragment>
      <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
        <Card className='ongoing-project'>
          <CardHeader className="card-no-border d-flex justify-content-between align-items-center">
            <Media body>
              <H5 attrH5={{ className: 'mb-0' }}>Roles Management</H5>
            </Media>
            <div className="d-flex justify-content-end align-items-center gap-2 mb-3">
              <Button
                outline
                color="primary"
                style={{ marginLeft: '0.5rem' }}
                onClick={fetchRoles}
              >
                <LuRefreshCcw />
              </Button>
              <Button
                color="primary"
                style={{ marginLeft: '0.5rem' }}
                onClick={() => toggleModal('add')}
              >
                <FaPlus />
              </Button>
              <Input
                type="text"
                style={{ maxWidth: '300px', marginLeft: '1rem' }}
                placeholder="Search here.."
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </CardHeader>
          <CardBody className="pt-0">
            <Table className="table-bordernone" responsive>
              <thead>
                <tr>
                  <th><H5>Name</H5></th>
                  <th><H5>Email</H5></th>
                  <th><H5>Permissions</H5></th>
                  <th><H5>Status</H5></th>
                  <th><H5>Actions</H5></th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredRoles.map((role) => (
                    <tr key={role._id}>
                      <td><H6>{role.name}</H6></td>
                      <td><H6>{role.email}</H6></td>
                      <td>
                        <H6>
                          {
                            role.permissions && Object.entries(role.permissions).map(([entity, perms]) =>
                              `${entity}: ${Object.keys(perms).filter(key => perms[key]).join(', ')}`).join(' | ') || 'N/A'
                          }
                        </H6>
                      </td>
                      <td>
                        <div className='badge badge-light-primary'>{role.status}</div>
                      </td>
                      <td>
                        <div className="d-flex flex-column flex-md-row justify-content-center gap-2">
                          <Button color="primary" onClick={() => handleEditClick(role)}><FaEdit /></Button>
                          <Button color="danger" onClick={() => handleDeleteClick(role)}><FaTrash /></Button>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Card>

      {/* Add/Edit Modal */}
      <Modal isOpen={addModal || editModal} toggle={() => toggleModal(editModal ? 'edit' : 'add')}>
        <ModalHeader toggle={() => toggleModal(editModal ? 'edit' : 'add')}>{editModal ? 'Edit Role' : 'Add Role'}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={selectedRole?.name || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={selectedRole?.email || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              type="password"
              name="password"
              id="password"
              value={selectedRole?.password || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Permissions</Label>
            <div className="permissions-container">
              {entities.map(entity => (
                <div key={entity} className="mb-3">
                  <H6>{entity.charAt(0).toUpperCase() + entity.slice(1)}</H6>
                  {permissionOptions.map(permission => (
                    <FormGroup check inline key={`${entity}-${permission}`}>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={selectedRole?.permissions?.[entity]?.[permission]}
                          onChange={() => handlePermissionChange(entity, permission)}
                        />
                        {permission.charAt(0).toUpperCase() + permission.slice(1)}
                      </Label>
                    </FormGroup>
                  ))}
                </div>
              ))}
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="status">Status</Label>
            <Input
              type="select"
              name="status"
              id="status"
              value={selectedRole?.status || 'active'}
              onChange={handleInputChange}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModal(editModal ? 'edit' : 'add')}>Cancel</Button>
          <Button color="primary" onClick={handleSave}>{editModal ? 'Update' : 'Add'}</Button>
        </ModalFooter>
      </Modal>

      {/* Delete Modal */}
      <Modal isOpen={deleteModal} toggle={() => toggleModal('delete')}>
        <ModalHeader toggle={() => toggleModal('delete')}>Delete Role</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the role "{selectedRole.name}"?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModal('delete')}>Cancel</Button>
          <Button color="danger" onClick={handleDelete}>Delete</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default OnGoing;