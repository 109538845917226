import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Table, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label
} from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import { FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  getAllCourses,
  createCourse,
  updateCourse,
  deleteCourse,
} from '../../Services/courseService';
import { toast } from 'react-toastify';

const CoursePage = () => {
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [modal, setModal] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted
    const fetchCourses = async () => {
      try {
        const courses = await getAllCourses();
        if (isMounted) {
          setCourses(courses);
          toast.success('Courses fetched successfully'); // Only call toast once here
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching courses:', error);
          toast.error('Error fetching courses');
        }
      }
    };

    fetchCourses();

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, []);


  useEffect(() => {
    filterCourses();
  }, [searchQuery, courses]);

  const fetchCourses = async () => {
    if (hasFetched) return; // Prevent further calls
    try {
      const courses = await getAllCourses();
      setCourses(courses);
      toast.success('Courses fetched successfully');
      setHasFetched(true); // Set the flag to true
    } catch (error) {
      console.error('Error fetching courses:', error);
      toast.error('Error fetching courses');
    }
  };

  const filterCourses = () => {
    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = courses.filter(course =>
        course.name.toLowerCase().includes(lowercasedQuery) ||
        course.description.toLowerCase().includes(lowercasedQuery)
      );
      setFilteredCourses(filtered);
    } else {
      setFilteredCourses(courses);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCourse({ ...currentCourse, [name]: value });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSave = async () => {
    try {
      if (isEdit) {
        const updatedCourse = await updateCourse(currentCourse._id, currentCourse);
        setCourses(courses.map((course) =>
          course._id === currentCourse._id ? updatedCourse : course
        ));
        toast.success('Course updated successfully');
      } else {
        const newCourse = await createCourse(currentCourse);
        setCourses([...courses, newCourse]);
        toast.success('Course created successfully');
      }
      toggleModal();
    } catch (error) {
      console.error('Error saving course:', error);
      toast.error('Error saving course');
    }
  };

  const handleEditClick = (course) => {
    setCurrentCourse(course);
    setIsEdit(true);
    toggleModal();
  };

  const handleDeleteClick = (course) => {
    setCourseToDelete(course);
    toggleDeleteModal();
  };

  const confirmDelete = async () => {
    try {
      await deleteCourse(courseToDelete._id);
      setCourses(courses.filter(course => course._id !== courseToDelete._id));
      toggleDeleteModal();
      toast.success('Course deleted successfully');
    } catch (error) {
      console.error('Error deleting course:', error);
      toast.error('Error deleting course');
    }
  };

  const handleAddNewClick = () => {
    setCurrentCourse({
      name: '',
      description: '',
      duration: '',
      level: 'Beginner',
      price: '',
      startDate: '',
      endDate: '',
      instructor: '',
      syllabus: []
    });
    setIsEdit(false);
    toggleModal();
  };

  return (
    <Fragment>
      <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
        <Card className='course-management'>
          <CardHeader className="card-no-border">
            <H5 attrH5={{ className: 'mb-0' }}>Course Management</H5>
          </CardHeader>
          <CardBody>
            <div className="d-flex justify-content-between mb-3">
              <Button color="primary" onClick={handleAddNewClick}>
                <FaPlus /> Add New Course
              </Button>
              <Input
                type="text"
                placeholder="Search by name or description..."
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ width: '300px' }}
              />
            </div>
            <Table className="table-bordernone" responsive>
              <thead>
                <tr>
                  <th><H5>Name</H5></th>
                  <th><H5>Description</H5></th>
                  <th><H5>Duration</H5></th>
                  <th><H5>Level</H5></th>
                  <th><H5>Price</H5></th>
                  <th><H5>Start Date</H5></th>
                  <th><H5>End Date</H5></th>
                  <th><H5>Instructor</H5></th>
                  <th><H5>Link</H5></th>
                  <th><H5>Actions</H5></th>
                </tr>
              </thead>
              <tbody>
                {filteredCourses.map((course) => (
                  <tr key={course._id}>
                    <td>
                      <H6>
                        <Link to={`/course/students/${course._id}`}>{course.name}</Link>
                      </H6>
                    </td>
                    <td><H6>{course.description}</H6></td>
                    <td><H6>{course.duration}</H6></td>
                    <td><H6>{course.level}</H6></td>
                    <td><H6>₹{course.price}</H6></td>
                    <td><H6>{new Date(course.startDate).toLocaleDateString()}</H6></td>
                    <td><H6>{new Date(course.endDate).toLocaleDateString()}</H6></td>
                    <td><H6>{course.instructor}</H6></td>
                    <td>
                      <H6>
                        <a href={`/course/${course._id}`} target="_blank" rel="noopener noreferrer">{course._id}</a>
                      </H6>
                    </td>                 <td>
                      <div className="d-flex gap-2">
                        <Button color="primary" size="sm" onClick={() => handleEditClick(course)}><FaEdit /> Edit</Button>
                        <Button color="danger" size="sm" onClick={() => handleDeleteClick(course)}><FaTrashAlt /> Delete</Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Card>

      {/* Add/Edit Modal */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{isEdit ? 'Edit Course' : 'Add New Course'}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={currentCourse?.name || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              value={currentCourse?.description || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="duration">Duration</Label>
            <Input
              type="text"
              name="duration"
              id="duration"
              value={currentCourse?.duration || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="level">Level</Label>
            <Input
              type="select"
              name="level"
              id="level"
              value={currentCourse?.level || 'Beginner'}
              onChange={handleInputChange}
            >
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="price">Price (₹)</Label>
            <Input
              type="number"
              name="price"
              id="price"
              value={currentCourse?.price || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="startDate">Start Date</Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              value={currentCourse?.startDate?.split('T')[0] || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="endDate">End Date</Label>
            <Input
              type="date"
              name="endDate"
              id="endDate"
              value={currentCourse?.endDate?.split('T')[0] || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="instructor">Instructor</Label>
            <Input
              type="text"
              name="instructor"
              id="instructor"
              value={currentCourse?.instructor || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="syllabus">Syllabus (Comma-separated topics)</Label>
            <Input
              type="text"
              name="syllabus"
              id="syllabus"
              value={currentCourse?.syllabus?.join(', ') || ''}
              onChange={(e) => setCurrentCourse({
                ...currentCourse,
                syllabus: e.target.value.split(',').map(item => item.trim())
              })}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>{isEdit ? 'Update' : 'Save'}</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete <strong>{courseToDelete?.name}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmDelete}>Delete</Button>{' '}
          <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default CoursePage;
