import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Table, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label
} from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import { FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import {
  getStudyMaterials,
  createStudyMaterial,
  updateStudyMaterial,
  deleteStudyMaterial,
} from '../../Services/studyMaterialService'; // Adjust the import path as needed
import { toast } from 'react-toastify';

const StudyMaterialPage = () => {
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [modal, setModal] = useState(false);
  const [currentMaterial, setCurrentMaterial] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [materialToDelete, setMaterialToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasFetched, setHasFetched] = useState(false);
  useEffect(() => {
    fetchStudyMaterials();
  }, []);

  useEffect(() => {
    filterMaterials();
  }, [searchQuery, studyMaterials]);

  const fetchStudyMaterials = async () => {
    setLoading(true);
    if (hasFetched) return; // Prevent further calls
    try {
      const materials = await getStudyMaterials();
      setStudyMaterials(materials);
      setFilteredMaterials(materials); // Initially, all materials are shown
      toast.success("Study materials fetched successfully");
      setHasFetched(true); // Set the flag to true
    } catch (error) {
      console.error('Error fetching study materials:', error);
      toast.error('Error fetching study materials');
    } finally {
      setLoading(false);
    }
  };

  const filterMaterials = () => {
    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = studyMaterials.filter(material =>
        material.title.toLowerCase().includes(lowercasedQuery)
      );
      setFilteredMaterials(filtered);
    } else {
      setFilteredMaterials(studyMaterials);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentMaterial({ ...currentMaterial, [name]: value });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSave = async () => {
    try {
      if (isEdit) {
        const updatedMaterial = await updateStudyMaterial(currentMaterial._id, currentMaterial);
        setStudyMaterials(studyMaterials.map((material) =>
          material._id === currentMaterial._id ? updatedMaterial : material
        ));
        toast.success("Study material updated successfully");
      } else {
        const newMaterial = await createStudyMaterial(currentMaterial);
        setStudyMaterials([...studyMaterials, newMaterial]);
        toast.success("New study material created successfully");
      }
      toggleModal();
    } catch (error) {
      console.error('Error saving study material:', error);
      toast.error('Error saving study material');
    }
  };

  const handleEditClick = (material) => {
    setCurrentMaterial(material);
    setIsEdit(true);
    toggleModal();
  };

  const handleDeleteClick = (material) => {
    setMaterialToDelete(material);
    toggleDeleteModal();
  };

  const confirmDelete = async () => {
    try {
      await deleteStudyMaterial(materialToDelete._id);
      setStudyMaterials(studyMaterials.filter(material => material._id !== materialToDelete._id));
      toggleDeleteModal();
      toast.success("Study material deleted successfully");
    } catch (error) {
      console.error('Error deleting study material:', error);
      toast.error('Error deleting study material');
    }
  };

  const handleAddNewClick = () => {
    setCurrentMaterial({ title: '', link: '', description: '' });
    setIsEdit(false);
    toggleModal();
  };

  return (
    <Fragment>
      <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
        <Card className='study-material-management'>
          <CardHeader className="card-no-border">
            <H5 attrH5={{ className: 'mb-0' }}>Study Material Management</H5>
          </CardHeader>
          <CardBody>
            <div className="d-flex justify-content-between mb-3">
              <Button color="primary" onClick={handleAddNewClick}>
                <FaPlus /> Add New Study Material
              </Button>
              <Input
                type="text"
                placeholder="Search by title..."
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ width: '300px' }}
              />
            </div>
            {loading ? (
              <div className="text-center">Loading study materials...</div>
            ) : (
              <Table className="table-bordernone" responsive>
                <thead>
                  <tr>
                    <th><H5>Title</H5></th>
                    <th><H5>Link</H5></th>
                    <th><H5>Description</H5></th>
                    <th><H5>Actions</H5></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMaterials.map((material) => (
                    <tr key={material._id}>
                      <td><H6>{material.title}</H6></td>
                      <td><H6><a href={material.link} target="_blank" rel="noopener noreferrer">{material.link}</a></H6></td>
                      <td><H6>{material.description}</H6></td>
                      <td>
                        <div className="d-flex gap-2">
                          <Button color="primary" size="sm" onClick={() => handleEditClick(material)}><FaEdit /> Edit</Button>
                          <Button color="danger" size="sm" onClick={() => handleDeleteClick(material)}><FaTrashAlt /> Delete</Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Card>

      {/* Add/Edit Modal */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{isEdit ? 'Edit Study Material' : 'Add New Study Material'}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              type="text"
              name="title"
              id="title"
              value={currentMaterial?.title || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="link">Link</Label>
            <Input
              type="text"
              name="link"
              id="link"
              value={currentMaterial?.link || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              value={currentMaterial?.description || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>{isEdit ? 'Update' : 'Save'}</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete <strong>{materialToDelete?.title}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmDelete}>Delete</Button>{' '}
          <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default StudyMaterialPage;
