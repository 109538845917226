import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Media, Button, FormGroup, Label, Row, Col,
} from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import { IoMdKey } from "react-icons/io";  // Key icon for API key setup
import { LuRefreshCcw } from "react-icons/lu";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Eye icons for toggling key visibility
import { getWapikonSetup, createWapikonSetup, updateWapikonSetup } from '../../Services/wapikonService';  // Importing Wapikon service functions
import TriggerTemplateMapping from "./TriggerComponent"
import { toast } from 'react-toastify';
const WapikonApiSetup = () => {
  const [wapikonSettings, setWapikonSettings] = useState({
    apiKey: '',
    merchantId: '',
    saltKey: '',
    redirectUrlBase: '',
    status: 'active',
  });
  const [showApiKey, setShowApiKey] = useState(false); // State to toggle API key visibility
  const [showMerchantId, setShowMerchantId] = useState(false); // State to toggle Merchant ID visibility
  const [showSaltKey, setShowSaltKey] = useState(false); // State to toggle Salt Key visibility
  const [showSaltIndex, setShowSaltIndex] = useState(false); // State to toggle Salt Key visibility
  const [isEditing, setIsEditing] = useState(false); // State to track if editing mode is enabled
  const [isNewSetup, setIsNewSetup] = useState(true); // State to track if it's a new setup

  useEffect(() => {
    // Fetch the Wapikon settings from the server
    const fetchSettings = async () => {
      try {
        const data = await getWapikonSetup();
        if (data) {
          setWapikonSettings(data);
          setIsNewSetup(false); // Existing setup
        } else {
          setIsNewSetup(true); // No setup found
        }
      } catch (error) {
        console.error('Error fetching Wapikon settings:', error);
      }
    };

    fetchSettings();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWapikonSettings({ ...wapikonSettings, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (isNewSetup) {
        // Create a new setup
        await createWapikonSetup(wapikonSettings);
      } else {
        // Update the existing setup
        await updateWapikonSetup(wapikonSettings._id, wapikonSettings);
        toast.success("Updated Successfully");
      }
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving Wapikon settings:', error);
      toast.error(error);
    }
  };

  return (
    <Fragment>
      <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
        <Card className='wapikon-api-settings'>
          <CardHeader className="card-no-border">
            <Media className="media-dashboard">
              <Media body>
                <H5 attrH5={{ className: 'mb-0' }}>Wapikon API Key Setup</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody>
            <div className="d-flex flex-wrap justify-content-end gap-2 mb-3">
              <Button color="primary" onClick={() => setIsEditing(!isEditing)}>
                <IoMdKey /> {isEditing ? 'Cancel' : 'Edit'}
              </Button>
              <Button outline color="primary" onClick={() => window.location.reload()}>
                <LuRefreshCcw /> Refresh
              </Button>
            </div>

            {isEditing ? (
              <Fragment>
                <FormGroup>
                  <Label for="apiKey">API Key</Label>
                  <Row>
                    <Col>
                      <Input
                        type={showApiKey ? "text" : "password"}
                        name="apiKey"
                        id="apiKey"
                        value={wapikonSettings?.apiKey}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col xs="auto">
                      <Button color="link" onClick={() => setShowApiKey(!showApiKey)}>
                        {showApiKey ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="merchantId">Phone Pay Merchant ID</Label>
                  <Row>
                    <Col>
                      <Input
                        type={showMerchantId ? "text" : "password"}
                        name="merchantId"
                        id="merchantId"
                        value={wapikonSettings?.merchantId}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col xs="auto">
                      <Button color="link" onClick={() => setShowMerchantId(!showMerchantId)}>
                        {showMerchantId ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="saltKey">Phone Pay Salt Key</Label>
                  <Row>
                    <Col>
                      <Input
                        type={showSaltKey ? "text" : "password"}
                        name="saltKey"
                        id="saltKey"
                        value={wapikonSettings?.saltKey}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col xs="auto">
                      <Button color="link" onClick={() => setShowSaltKey(!showSaltKey)}>
                        {showSaltKey ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="saltKey">Phone Pay Salt Index</Label>
                  <Row>
                    <Col>
                      <Input
                        type={showSaltIndex ? "text" : "password"}
                        name="saltIndex"
                        id="saltIndex"
                        value={wapikonSettings?.saltIndex}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col xs="auto">
                      <Button color="link" onClick={() => setShowSaltIndex(!showSaltIndex)}>
                        {showSaltIndex ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="redirectUrlBase">Redirect URL Base</Label>
                  <Input
                    type="text"
                    name="redirectUrlBase"
                    id="redirectUrlBase"
                    value={wapikonSettings?.redirectUrlBase}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    value={wapikonSettings?.status}
                    onChange={handleInputChange}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                </FormGroup>
                <Button color="primary" onClick={handleSave}>Save</Button>
              </Fragment>
            ) : (
              <Fragment>
                <FormGroup>
                  <Label for="apiKey">API Key</Label>
                  <Row>
                    <Col>
                      <H6>{showApiKey ? wapikonSettings.apiKey : '*************'}</H6>
                    </Col>
                    <Col xs="auto">
                      <Button color="link" onClick={() => setShowApiKey(!showApiKey)}>
                        {showApiKey ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="merchantId">Merchant ID</Label>
                  <Row>
                    <Col>
                      <H6>{showMerchantId ? wapikonSettings.merchantId : '*************'}</H6>
                    </Col>
                    <Col xs="auto">
                      <Button color="link" onClick={() => setShowMerchantId(!showMerchantId)}>
                        {showMerchantId ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="saltKey">Salt Key</Label>
                  <Row>
                    <Col>
                      <H6>{showSaltKey ? wapikonSettings.saltKey : '*************'}</H6>
                    </Col>
                    <Col xs="auto">
                      <Button color="link" onClick={() => setShowSaltKey(!showSaltKey)}>
                        {showSaltKey ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="redirectUrlBase">Redirect URL Base</Label>
                  <H6>{wapikonSettings.redirectUrlBase}</H6>
                </FormGroup>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <H6>{wapikonSettings.status === 'active' ? 'Active' : 'Inactive'}</H6>
                </FormGroup>
              </Fragment>
            )}
          </CardBody>
        </Card>
      </Card>
      <TriggerTemplateMapping />
    </Fragment>
  );
};

export default WapikonApiSetup;
