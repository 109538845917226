import SamplePage from '../Pages/Sample Page';
import Students from '../Pages/Students';
import { ADMIN_URL, IB_URL, STUDENT_URL } from '../Constant';
import DashBoard from '../Pages/DashBoard';
import IBDashboard from '../Pages/IBDashboard';
import IBProfile from '../Pages/IBProfile';
import NotFound from '../Pages/NotFound';
import Approved from '../Pages/approved';
import Pending from '../Pages/pending';
import UsersEdit from '../Pages/StudentDashBoard/Users/UsersEdit';
import TradingAccountPage from '../Pages/StudentDashBoard/tradingAccountPage';
import EnrollCoursePage from '../Pages/StudentDashBoard/EnrollCoursePage';
import AdminProfile from '../Component/profile';
import Profile from '../Pages/profile';
import AddIB from '../Pages/AddIB';
import CreateBatch from '../Pages/Batch';
import AccessControl from '../Pages/AccessControl';
import ViewFormPage from '../Pages/ViewFormPage';
import CreateForm from '../Pages/Form';
import CoursePage from '../Pages/Courses';
import CourseStudent from '../Pages/CourseStudents';
import FormBuilder from '../Component/Form Page';
import SmtpConfigurationPage from '../Pages/SMTPConfiguration';
// import WapikonApiSetup from '../Component/WapikonSetup';
import StudyMaterialPage from '../Component/StudyMaterial';
import FormBuilderPage from '../Pages/FormBuilder';
import UpcomingPage from '../Pages/UpcomingPage';
import StudyMaterial from '../Pages/StudyMaterial';
import UpcomingEvents from '../Pages/IBDashboard/UpcomingEvents';
import UpcomingEventsStudent from '../Pages/StudentDashBoard/UpcomingEvents';
import AddStudent from '../Pages/IBDashboard/AddStudents';
import PayoutPage from '../Pages/IBDashboard/PayoutPage';
import WapikonApiSetupPage from '../Pages/WapikonSetup';
import AddEventsPage from '../Pages/AddEvents';
import AddBrokersPage from '../Pages/AddBroker';
import StudentStudyMaterial from '../Pages/StudentStudyMaterial';
import AddSubIB from '../Pages/IBDashboard/AddSubIB';
export const adminRoutes = [
    {
        path: `${ADMIN_URL}/*`, Component: <NotFound />
    },
    {
        path: `${ADMIN_URL}/sample-page`, Component: <SamplePage />
    },
    {
        path: `${ADMIN_URL}/dashboard`, Component: <DashBoard />
    },
    {
        path: `${ADMIN_URL}/students`, Component: <Students />
    },
    {
        path: `${ADMIN_URL}/add-events`, Component: <AddEventsPage />
    },
    {
        path: `${ADMIN_URL}/profile`, Component: <Profile />
    },
    {
        path: `${ADMIN_URL}/add-ib`, Component: <AddIB />
    },
    {
        path: `${ADMIN_URL}/mail-setup`, Component: <SmtpConfigurationPage />
    },
    {
        path: `${ADMIN_URL}/wapikon-setup`, Component: <WapikonApiSetupPage />
    },
    {
        path: `${ADMIN_URL}/study-material`, Component: <StudyMaterial />
    },
    {
        path: `${ADMIN_URL}/courses`, Component: <CoursePage />
    },
    {
        path: `${ADMIN_URL}/course/students/:id`, Component: <CourseStudent />
    },
    {
        path: `${ADMIN_URL}/create-batch`, Component: <CreateBatch />
    },
    {
        path: `${ADMIN_URL}/access-control`, Component: <AccessControl />
    },
    {
        path: `${ADMIN_URL}/form`, Component: <CreateForm />
    },
    {
        path: `${ADMIN_URL}/view-form/:id`, Component: <ViewFormPage />
    },
    {
        path: `${ADMIN_URL}/form-builder`, Component: <FormBuilderPage />
    },
    {
        path: `${ADMIN_URL}/email`, Component: <UpcomingPage />
    },
    {
        path: `${ADMIN_URL}/whatsapp`, Component: <UpcomingPage />
    },
    {
        path: `${ADMIN_URL}/brokers`, Component: <AddBrokersPage />
    },
];
export const studentRoutes = [
    {
        path: `${STUDENT_URL}/*`, Component: <NotFound />
    },
    {
        path: `/${STUDENT_URL}/profile`, Component: <UsersEdit />
    },
    {
        path: `/${STUDENT_URL}/upcoming-events`, Component: <UpcomingEventsStudent />
    },
    {
        path: `/${STUDENT_URL}/enroll_course`, Component: <EnrollCoursePage />
    },
    {
        path: `/${STUDENT_URL}/trading_account`, Component: <TradingAccountPage />
    },
    {
        path: `/${STUDENT_URL}/study-material`, Component: <StudentStudyMaterial />
    },
];
export const ibRoutes = [
    // {
    //     path: `/${IB_URL}/students`, Component: <IBDashboard />
    // },
    {
        path: `/${IB_URL}/upcoming-events`, Component: <UpcomingEvents />
    },
    {
        path: `/${IB_URL}/add-students`, Component: <AddStudent />
    },
    {
        path: `/${IB_URL}/add-subib`, Component: <AddSubIB />
    },
    {
        path: `/${IB_URL}/profile`, Component: <IBProfile />
    },
    {
        path: `/${IB_URL}/payout`, Component: <PayoutPage />
    },
    {
        path: `${IB_URL}/*`, Component: <NotFound />
    },
];