import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Input, Table, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { getFormResponses } from '../../Services/formResponseService';

const ViewFormPage = () => {
    const { id } = useParams(); // Get the form ID from the URL parameters
    const [responses, setResponses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchResponses();
    }, [id]);

    const fetchResponses = async () => {
        try {
            const responseData = await getFormResponses(id);
            setResponses(responseData);
        } catch (error) {
            console.error('Error fetching form responses:', error);
        }
    };

    const filteredResponses = responses.filter(response =>
        response.submittedBy.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const exportToExcel = () => {
        const data = filteredResponses.map((response) => {
            const result = {
                "Submitted By": response.submittedBy,
                "Phone":response.phone,
                "Submitted At": new Date(response.submittedAt).toLocaleString(),
            };
            response.responses.forEach((resp) => {
                result[resp.fieldLabel] = resp.response;
            });
            return result;
        });

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Form Responses');
        XLSX.writeFile(wb, `form_responses_${id}.xlsx`);
    };
    // test

    return (
        <Fragment>
            <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
                <Card className='ongoing-project'>
                    <CardHeader className="card-no-border d-flex justify-content-between align-items-center">
                        <h4>Form Responses</h4>
                        <Button color="primary" onClick={exportToExcel}>
                            Export to Excel
                        </Button>
                    </CardHeader>
                    <div className="d-flex justify-content-end gap-2" style={{ paddingRight: '4px' }}>
                        <Input
                            type="text"
                            style={{ width: '28rem' }}
                            placeholder="Search here.."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <CardBody className="pt-0">
                        <CardBody className="table-responsive">
                            <Table className={`table-bordernone`}>
                                <thead>
                                    <tr>
                                        <th>Submitted By</th>
                                        <th>Phone Number</th>
                                        <th>Submitted At</th>
                                        {responses.length > 0 && responses[0].responses.map((resp, index) => (
                                            <th key={index}>{resp.fieldLabel}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredResponses.map((response, index) => (
                                        <tr key={index}>
                                            <td>{response.submittedBy}</td>
                                            <td>{response.phone}</td>
                                            <td>{new Date(response.submittedAt).toLocaleString()}</td>
                                            {response.responses.map((resp, idx) => (
                                                <td key={idx}>
                                                    {resp.fieldType === 'file' ? (
                                                        <a href={`${window.location.origin}/${resp.response}`} target="_blank" rel="noopener noreferrer">
                                                            {resp.response.split('\\').pop()}
                                                        </a>
                                                    ) : (
                                                        resp.response
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </CardBody>
                </Card>
            </Card>
        </Fragment>
    );
};

export default ViewFormPage;
