const axios = require('axios');

// Base URL for the API
const API_URL = process.env.REACT_APP_API_URL || "api"

// Create a payment order
exports.createPaymentOrder = async (paymentData) => {
    try {
        const response = await axios.post(`${API_URL}/payment/order`, paymentData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('studentToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating payment order:', error);
        throw error;
    }
};
// Create a payment order
exports.createCoursePaymentOrder = async (paymentData) => {
    try {
        const response = await axios.post(`${API_URL}/payment/course/order`, paymentData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('studentToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating payment order:', error);
        throw error;
    }
};

// Check payment status
exports.checkPaymentStatus = async (transactionId) => {
    try {
        const response = await axios.post(`${API_URL}/payment/status?id=${transactionId}`, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('studentToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(`Error checking payment status for transaction ID ${transactionId}:`, error);
        throw error;
    }
};
