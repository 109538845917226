import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AddStudentContain from '../../../Component/IBDashBoard/AddStudent';

const AddStudent = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="IB" title="Add-Students" />
      <AddStudentContain/>
    </Fragment>
  );
};
export default AddStudent;