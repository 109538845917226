import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AddSubIBtContain from '../../../Component/IBDashBoard/AddSubIB';

const AddSubIB = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="IB" title="Add-SubIB" />
      <AddSubIBtContain/>
    </Fragment>
  );
};
export default AddSubIB;