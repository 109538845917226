import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Table, Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { LuRefreshCcw } from "react-icons/lu";
import { toast } from 'react-toastify';
import {
  getIbPayouts
} from '../../../Services/ibPersonalService'; // Import the service to fetch payouts

const PayoutPage = () => {
  const [payouts, setPayouts] = useState([]);  // To store payout data
  const [totalPayout, setTotalPayout] = useState(0);  // To store total payout amount
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [payoutsPerPage] = useState(10);

  // Fetch payouts on component mount
  useEffect(() => {
    fetchPayouts();
  }, []);

  const fetchPayouts = async () => {
    try {
      const data = await getIbPayouts();  // Use service to get IB payouts
      setPayouts(data.payouts);
      setTotalPayout(data.totalPayout);  // Set the total payout amount
    } catch (error) {
      console.error('Error fetching payouts:', error);
      toast.error('Failed to fetch payouts');
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Search and Filter Logic
  const filteredPayouts = payouts.filter(payout =>
    payout.ibId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    payout.studentId.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination Logic
  const indexOfLastPayout = currentPage * payoutsPerPage;
  const indexOfFirstPayout = indexOfLastPayout - payoutsPerPage;
  const currentPayouts = filteredPayouts.slice(indexOfFirstPayout, indexOfLastPayout);
  const totalPages = Math.ceil(filteredPayouts.length / payoutsPerPage);

  return (
    <Fragment>
      <Card style={{ width: '100%' }}>
        <CardHeader>
          <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center">
            <H5>Total Payout: ₹{totalPayout}</H5>  {/* Display the total payout */}
            <div className="d-flex flex-wrap align-items-center">
              <div className='d-flex flex-wrap gap-2' style={{ marginRight: '10px' }}>
                <div className="search-box mb-2 mb-md-0 mr-2 mr-md-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by IB ID or Student ID..."
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className='d-flex flex-wrap gap-2'>
                <Btn
                  attrBtn={{
                    color: 'primary',
                    className: 'badge-light d-block btn-mail',
                    onClick: fetchPayouts
                  }}
                >
                  <LuRefreshCcw />
                </Btn>
              </div>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <Table responsive className="table-responsive">
            <thead>
              <tr>
                <th scope="col">Student ID</th>
                <th scope="col">Payout Amount (₹)</th>
                <th scope="col">Payment Amount (₹)</th>
                <th scope="col">Percentage (%)</th>
                <th scope="col">Trasaction Id</th>
              </tr>
            </thead>
            <tbody>
              {currentPayouts.map((payout) => (
                <tr key={payout._id}>
                  <td>{payout.studentId}</td>
                  <td>{payout.amount}</td>
                  <td>{payout.paymentAmount}</td>
                  <td>{(payout.percentage).toFixed(2)}</td>  {/* Convert to percentage */}
                  <td>{payout.transactionId}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                  first
                  onClick={() => handlePageChange(1)}
                />
              </PaginationItem>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                  previous
                  onClick={() => handlePageChange(currentPage - 1)}
                />
              </PaginationItem>
              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem
                  key={index}
                  active={currentPage === index + 1}
                >
                  <PaginationLink onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink
                  next
                  onClick={() => handlePageChange(currentPage + 1)}
                />
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink
                  last
                  onClick={() => handlePageChange(totalPages)}
                />
              </PaginationItem>
            </Pagination>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default PayoutPage;
