import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Media, Row, Col, InputGroup } from 'reactstrap';
import { Btn, H3, H4, H6, Image, P } from '../../AbstractElements';
import { MyProfile, Bio, Password, Website, Save, EmailAddress, Phone, Gender, Status, PUBLIC_URL } from '../../Constant';
import { Link } from 'react-router-dom';
import { getStudentProfile, updateStudentProfile } from '../../Services/StudentPersonal';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';

const MyProfileEdit = () => {
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    password: '',
    website: '',
    phone: '',
    gender: 'male',
    status: 'active',
    profilePicture: '',
    idCard: '',
    referralCode: ''
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const data = await getStudentProfile();
      setProfile({
        name: data.name,
        email: data.email,
        password: '', // Password should not be fetched for security reasons
        website: data.website || '',
        phone: data.phone || '',
        gender: data.gender || 'male',
        status: data.status || 'active',
        profilePicture: data.profilePicture || `https://icon-library.com/images/user-png-icon/user-png-icon-16.jpg`,
        idCard: data.idCard || '',
        referralCode: data?.referralCode
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSave = async () => {
    try {
      await updateStudentProfile(profile);
      toast.success('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile.');
    }
  };

  const handleDownloadIdCard = () => {
    if (profile.idCard) {
      const link = document.createElement('a');
      link.href = profile.idCard;
      link.download = `${profile.name}-IDCard.pdf`;
      link.click();
    } else {
      alert('ID Card is not available.');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <Card>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>{MyProfile}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="mb-2 align-items-center">
              <div className="profile-title">
                <Media>
                  <Image attrImage={{ className: 'img-70 rounded-circle', alt: '', src: profile.profilePicture }} />
                  <Media body>
                    <H3 attrH3={{ className: 'mb-1 f-20 txt-primary' }}>{profile.name}
                    </H3>
                    <P>STUDENT</P>
                  </Media>
                </Media>
              </div>
            </Row>
            {/* <FormGroup className="mb-3">
              <H6 attrH6={{ className: 'form-label' }}>{Bio}</H6>
              <Input
                type="textarea"
                className="form-control"
                rows="5"
                name="bio"
                value={profile.bio}
                onChange={handleInputChange}
              />
            </FormGroup> */}
            <Row>
              <Col md={6} className="mb-3">
              <FormGroup className="mb-3">
                  <Label className="form-label">{EmailAddress}</Label>
                  <Input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="your-email@domain.com"
                    value={profile.email}
                    onChange={handleInputChange}
                  />
                </FormGroup>
               
              </Col>
              <Col md={6} className="mb-3">
              <FormGroup>
                  <Label className="form-label">Referrel Code</Label>
                  <Input
                    type="text"
                    className="form-control"
                    name="phone"
                    placeholder="Your Referrel Code"
                    value={profile.referralCode}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              {/* <Col md={6} className="mb-3">
                <FormGroup>
                  <Label className="form-label">{Phone}</Label>
                  <Input
                    type="text"
                    className="form-control"
                    name="phone"
                    placeholder="Your phone number"
                    value={profile.phone}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col> */}
             <Col md={6} className="mb-3">
              <FormGroup>
            <Label for="phone">Mobile Number</Label>
            <InputGroup className="mobile-input-group">
              <div className="phone-input-container" style={{ display: 'flex', width: '100%' }}>
                <PhoneInput
                  country={'us'}
                  value={profile.phone}
                  onChange={phone => setProfile({ ...profile, phone })}
                  inputStyle={{
                    width: '100%',
                    borderRadius: '0 0.375rem 0.375rem 0',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                    paddingLeft: '40px', // Adjusted padding for better alignment
                    marginLeft: '8px',   // Added margin to create gap between dropdown and input
                    flexGrow: 1          // Ensures the input takes up the full remaining width
                  }}
                  buttonStyle={{
                    borderRadius: '0.375rem 0 0 0.375rem',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                  }}
                  containerClass="phone-input-container"
                />
              </div>
            </InputGroup>
          </FormGroup>
          </Col>
              <Col md={6} className="mb-3">
                <FormGroup>
                  <Label className="form-label">{Gender}</Label>
                  <Input
                    type="select"
                    name="gender"
                    value={profile.gender}
                    onChange={handleInputChange}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup className="mb-3">
              <Label className="form-label">{Status}</Label>
              <Input
                type="select"
                name="status"
                value={profile.status}
                onChange={handleInputChange}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Input>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{Password}</Label>
              <Input
                type="password"
                className="form-control"
                name="password"
                placeholder="*********"
                value={profile.password}
                onChange={handleInputChange}
              />
            </FormGroup>
            <div className="form-footer">
              <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'button', onClick: handleSave }}>{Save}</Btn>
            </div>
            <div className="form-footer mt-3">
              <Btn attrBtn={{ className: 'btn-block', color: 'secondary', type: 'button', onClick: handleDownloadIdCard }}>
                Download ID Card
              </Btn>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default MyProfileEdit;
