import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Table, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Pagination, PaginationItem, PaginationLink,
  InputGroup
} from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { IoMdPersonAdd } from "react-icons/io";
import { LuRefreshCcw } from "react-icons/lu";
import { toast } from 'react-toastify';
import {
  getIbStudents, addIbStudent
} from '../../../Services/ibPersonalService';

import PhoneInput from 'react-phone-input-2';

const OnGoing = () => {
  const [addModal, setAddModal] = useState(false);
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(10);
  const [newStudent, setNewStudent] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    gender: 'male',
  });

  // Fetch referred students on component mount
  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      const data = await getIbStudents();  // Use service to get IB referred students
      setStudents(data);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const toggleModal = () => {
    setAddModal(!addModal);
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewStudent({ ...newStudent, [name]: value });
  };

  const handleCreate = async () => {
    try {
      await addIbStudent(newStudent);  // Call addIbStudent from ibPersonalService
      fetchStudents();  // Refresh students list after adding new student
      toggleModal();
      setNewStudent({
        name: '',
        email: '',
        phone: '',
        password: '',
        gender: 'male',
      });
      toast.success("Student Added");
    } catch (error) {
      toast.error("Failed to Create student: " + error.message);
      console.error('Error creating student:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Search and Filter Logic
  const filteredStudents = students.filter(student =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    student.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination Logic
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);
  const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

  return (
    <Fragment>
      <Card style={{ width: '100%' }}>
        <CardHeader>
          <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center">
            <H5>Students</H5>
            <div className="d-flex flex-wrap align-items-center">
              <div className='d-flex flex-wrap gap-2' style={{ marginRight: '10px' }}>
                <div className="search-box mb-2 mb-md-0 mr-2 mr-md-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className='d-flex flex-wrap gap-2'>
                <Btn
                  attrBtn={{
                    color: 'primary',
                    className: 'badge-light d-block btn-mail',
                    onClick: fetchStudents
                  }}
                >
                  <LuRefreshCcw />
                </Btn>
                <Btn
                  attrBtn={{
                    color: 'primary',
                    className: 'badge-light d-block btn-mail',
                    onClick: toggleModal
                  }}
                >
                  <IoMdPersonAdd />
                </Btn>
              </div>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <Table responsive className="table-responsive">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Gender</th>
              </tr>
            </thead>
            <tbody>
              {currentStudents.map((student) => (
                <tr key={student._id}>
                  <td>{student.name}</td>
                  <td>{student.email}</td>
                  <td>{student.phone}</td>
                  <td>{student.gender}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                  first
                  onClick={() => handlePageChange(1)}
                />
              </PaginationItem>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                  previous
                  onClick={() => handlePageChange(currentPage - 1)}
                />
              </PaginationItem>
              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem
                  key={index}
                  active={currentPage === index + 1}
                >
                  <PaginationLink onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink
                  next
                  onClick={() => handlePageChange(currentPage + 1)}
                />
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink
                  last
                  onClick={() => handlePageChange(totalPages)}
                />
              </PaginationItem>
            </Pagination>
          </div>
        </CardBody>
      </Card>

      {/* Create Modal */}
      <Modal isOpen={addModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Student</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={newStudent.name}
              onChange={handleNewInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={newStudent.email}
              onChange={handleNewInputChange}
            />
          </FormGroup>
          {/* <FormGroup>
            <Label for="phone">Phone</Label>
            <Input
              type="text"
              id="phone"
              name="phone"
              value={newStudent.phone}
              onChange={handleNewInputChange}
            />
          </FormGroup> */}
          <FormGroup>
            <Label for="phone">Phone</Label>
            <InputGroup className="mobile-input-group">
              <div className="phone-input-container" style={{ display: 'flex', width: '100%' }}>
                <PhoneInput
                  country={'us'}
                  value={newStudent.phone}
                  onChange={phone => setNewStudent({ ...newStudent, phone })}
                  inputStyle={{
                    width: '100%',
                    borderRadius: '0 0.375rem 0.375rem 0',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                    paddingLeft: '40px', // Adjusted padding for better alignment
                    marginLeft: '8px',   // Added margin to create gap between dropdown and input
                    flexGrow: 1          // Ensures the input takes up the full remaining width
                  }}
                  buttonStyle={{
                    borderRadius: '0.375rem 0 0 0.375rem',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                  }}
                  containerClass="phone-input-container"
                />
              </div>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              type="password"
              id="password"
              name="password"
              value={newStudent.password}
              onChange={handleNewInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="gender">Gender</Label>
            <Input
              type="select"
              id="gender"
              name="gender"
              value={newStudent.gender}
              onChange={handleNewInputChange}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCreate}>Create</Button>
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default OnGoing;
