const axios = require('axios');

// Base URL for the API
const API_URL =  process.env.REACT_APP_API_URL ||  "api" 

// Get all courses
exports.getAllCourses = async () => {
    try {
        const response = await axios.get(`${API_URL}/courses`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching courses:', error);
        throw error;
    }
};

// Get a course by ID
exports.getCourseById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/courses/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching course with ID ${id}:`, error);
        throw error;
    }
};
// Get Student's by  course  ID
exports.getStudentsByCourseId = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/courses/students/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching course with ID ${id}:`, error);
        throw error;
    }
};

// Create a new course
exports.createCourse = async (courseData) => {
    try {
        const response = await axios.post(`${API_URL}/courses`, courseData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating course:', error);
        throw error;
    }
};

// Update a course by ID
exports.updateCourse = async (id, courseData) => {
    try {
        const response = await axios.put(`${API_URL}/courses/${id}`, courseData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating course with ID ${id}:`, error);
        throw error;
    }
};

// Delete a course by ID
exports.deleteCourse = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/courses/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(`Error deleting course with ID ${id}:`, error);
        throw error;
    }
};
