import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import CoursePage from '../../Component/CourseStudent';

const CourseStudent = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="" />
      <CoursePage />
    </Fragment>
  );
};
export default CourseStudent;