import { HomeSvg, SamplePageSvg, LearningSvg, KnowledgebaseSvg, GallarySvg, ChartsSvg, FormsSvg } from '../../Data/svgIcons';
import { IB_URL } from '../../Constant';
export const MENUITEMS = [
    // {
    //     menutitle: 'Refered Students',
    //     Items: [
    //         {
    //             title: 'Refered Students', icon: KnowledgebaseSvg, type: 'link', active: false, path: `${IB_URL}/students`
    //         },
    //     ]
    // },
    {
        menutitle: 'Profile',
        Items: [
            {
                title: 'Profile', icon: KnowledgebaseSvg, type: 'link', active: false, path: `${IB_URL}/profile`
            },
        ]
    },
    {
        menutitle: 'Add Students',
        Items: [
            {
                title: 'Add Students', icon: KnowledgebaseSvg, type: 'link', active: false, path: `${IB_URL}/add-students`
            },
        ]
    },
    {
        menutitle: 'Add Sub-IB',
        Items: [
            {
                title: 'Add Sub-IB', icon: KnowledgebaseSvg, type: 'link', active: false, path: `${IB_URL}/add-subib`
            },
        ]
    },
    {
        menutitle: 'Upcoming Events',
        Items: [
            {
                title: 'Upcoming Events', icon: KnowledgebaseSvg, type: 'link', active: false, path: `${IB_URL}/upcoming-events`
            },
        ]
    },
    {
        menutitle: 'Payout',
        Items: [
            {
                title: 'Payout', icon: KnowledgebaseSvg, type: 'link', active: false, path: `${IB_URL}/payout `
            },
        ]
    },
    {
        menutitle: 'Support',
        Items: [
            {
                title: 'Support', icon: KnowledgebaseSvg, type: 'link', active: false, path: `mailto:support@thelearningpower.com`
            },
        ]
    },

    // {
    //     menutitle: 'Profile',
    //     Items: [
    //         {
    //             title: 'Profile', icon: GallarySvg, type: 'link', active: false, path: `${IB_URL}/profile`
    //         },
    //     ]
    // },
    // {
    //     menutitle: 'Upcoming Events',
    //     Items: [
    //         {
    //             title: 'Upcoming Events', icon: FormsSvg, type: 'link', active: false, path: `${IB_URL}/events`
    //         },
    //     ]
    // },
    // {
    //     menutitle: 'Open Trading Account',
    //     Items: [
    //         {
    //             title: 'Open Trading Account', icon: LearningSvg, type: 'link', active: false, path: `${IB_URL}/pending`
    //         },
    //     ]
    // },
    // {
    //     menutitle: 'Enroll As IB',
    //     Items: [
    //         {
    //             title: 'Enroll As IB', icon: ChartsSvg, type: 'link', active: false, path: `${IB_URL}/basic_course`
    //         },
    //     ]
    // },

    // {
    //     menutitle: 'Study Material',
    //     Items: [
    //         {                                       // sub for nested and link for linked
    //             title: 'Study Material', icon: HomeSvg, type: 'sub', active: false, children: [
    //                 //childrens
    //                 { path: `${IB_URL}/advanced1`, icon: SamplePageSvg, title: 'pdf', type: 'link' },
    //                 { path: `${IB_URL}/advanced2`, icon: SamplePageSvg, title: 'videos', type: 'link' }
    //             ]
    //         },
    //     ]
    // },
    // {
    //     menutitle: 'Support',
    //     Items: [
    //         {
    //             title: 'IB Incentive Plans', icon: HomeSvg, type: 'sub', active: false, children: [
    //                 { path: 'http://support.pixelstrap.com/help-center', icon: SamplePageSvg, title: 'Raised ticket', type: 'external_link' }
    //             ]
    //         },
    //     ]
    // },
];
