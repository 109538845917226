import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Table, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Pagination, PaginationItem, PaginationLink,
  InputGroup
} from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { IoMdPersonAdd } from "react-icons/io";
import { LuRefreshCcw } from "react-icons/lu";
import { toast } from 'react-toastify';
import {
  getAllSubIbs, createSubIb, updateSubIb, deleteSubIb
} from
  '../../../Services/ibPersonalService';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const SubIbPage = () => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSubIb, setSelectedSubIb] = useState(null);
  const [subIbs, setSubIbs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [subIbsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [newSubIb, setNewSubIb] = useState({
    name: '',
    email: '',
    phone: '',
    password: '', // Added password field for Sub-IB creation
    profitPercentage: 0
  });

  // Fetch sub-IBs on component mount
  useEffect(() => {
    fetchSubIbs();
  }, [currentPage]);

  const fetchSubIbs = async () => {
    try {
      const data = await getAllSubIbs();
      setSubIbs(data);
      setTotalPages(Math.ceil(data.length / subIbsPerPage));
    } catch (error) {
      console.error('Error fetching Sub-IBs:', error);
    }
  };

  const toggleModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setAddModal(!addModal);
        break;
      case 'edit':
        setEditModal(!editModal);
        break;
      case 'delete':
        setDeleteModal(!deleteModal);
        break;
      default:
        break;
    }
  };

  const handleEditClick = async (subIbId) => {
    try {
      const subIb = subIbs.find((ib) => ib._id === subIbId);
      setSelectedSubIb(subIb);
      setEditModal(true);
    } catch (error) {
      console.error('Error fetching Sub-IB:', error);
    }
  };

  const handleDeleteClick = (subIbId) => {
    const subIb = subIbs.find((ib) => ib._id === subIbId);
    setSelectedSubIb(subIb);
    setDeleteModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedSubIb({ ...selectedSubIb, [name]: value });
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewSubIb({ ...newSubIb, [name]: value });
  };

  const handleCreate = async () => {
    try {
      await createSubIb(newSubIb);
      fetchSubIbs();
      toggleModal('add');
      setNewSubIb({
        name: '',
        email: '',
        phone: '',
        profitPercentage: 0,
        password: '' // Reset fields
      });
      toast.success("Sub-IB Added");
    } catch (error) {
      toast.error("Failed to Create Sub-IB: " + error.message);
      console.error('Error creating Sub-IB:', error);
    }
  };

  const handleSave = async () => {
    try {
      await updateSubIb(selectedSubIb._id, selectedSubIb);
      toast.success("Updated Sub-IB Successfully");
      fetchSubIbs();
      toggleModal('edit');
    } catch (error) {
      console.error('Error saving Sub-IB:', error.message);
      toast.error(`Error saving Sub-IB: ${error.message}`);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteSubIb(selectedSubIb._id);
      fetchSubIbs();
      toggleModal('delete');
      toast.success("Sub-IB Deleted Successfully");
    } catch (error) {
      console.error('Error deleting Sub-IB:', error);
      toast.error('Error deleting Sub-IB:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pagesToShow = 10;
    let startPage = currentPage <= pagesToShow ? 1 : currentPage - 5;
    let endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationItem key={i} active={currentPage === i}>
          <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return pageNumbers;
  };

  const filteredSubIbs = subIbs.filter(subIb =>
    subIb.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    subIb.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Fragment>
      <Card style={{ width: '100%' }}>
        <CardHeader>
          <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center">
            <H5>Sub-IBs</H5>
            <div className="d-flex flex-wrap align-items-center">
              <div className='d-flex flex-wrap gap-2' style={{ marginRight: '10px' }}>
                <div className="search-box mb-2 mb-md-0 mr-2 mr-md-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className='d-flex flex-wrap gap-2'>
                <Btn
                  attrBtn={{
                    color: 'primary',
                    className: 'badge-light d-block btn-mail',
                    onClick: fetchSubIbs
                  }}
                >
                  <LuRefreshCcw />
                </Btn>
                <Btn
                  attrBtn={{
                    color: 'primary',
                    className: 'badge-light d-block btn-mail',
                    onClick: () => toggleModal('add')
                  }}
                >
                  <IoMdPersonAdd />
                </Btn>
              </div>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <Table responsive className="table-responsive">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Payout %</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredSubIbs.map((subIb) => (
                <tr key={subIb._id}>
                  <td>{subIb.name}</td>
                  <td>{subIb.email}</td>
                  <td>{subIb.phone}</td>
                  <td>{subIb.profitPercentage}</td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() => handleEditClick(subIb._id)}
                    >
                      Edit
                    </Button>{' '}
                    <Button
                      color="danger"
                      onClick={() => handleDeleteClick(subIb._id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink first onClick={() => handlePageChange(1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
              </PaginationItem>
              {renderPageNumbers()}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink last onClick={() => handlePageChange(totalPages)} />
              </PaginationItem>
            </Pagination>
          </div>
        </CardBody>
      </Card>

      {/* Create Modal */}
      <Modal isOpen={addModal} toggle={() => toggleModal('add')}>
        <ModalHeader toggle={() => toggleModal('add')}>Add New Sub-IB</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={newSubIb.name}
              onChange={handleNewInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={newSubIb.email}
              onChange={handleNewInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="phone">Phone</Label>
            <InputGroup className="mobile-input-group">
              <PhoneInput
                country={'us'}
                value={newSubIb.phone}
                onChange={phone => setNewSubIb({ ...newSubIb, phone })}
                inputStyle={{
                  width: '100%',
                  borderRadius: '0 0.375rem 0.375rem 0',
                  height: 'calc(1.5em + 0.75rem + 2px)',
                  paddingLeft: '40px',
                  marginLeft: '8px',
                  flexGrow: 1
                }}
                buttonStyle={{
                  borderRadius: '0.375rem 0 0 0.375rem',
                  height: 'calc(1.5em + 0.75rem + 2px)',
                }}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              type="password"
              id="password"
              name="password"
              value={newSubIb.password}
              onChange={handleNewInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="profitPercentage">Payout  %</Label>
            <Input
              type="number"
              id="profitPercentage"
              name="profitPercentage"
              value={newSubIb.profitPercentage}
              onChange={handleNewInputChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCreate}>Create</Button>
          <Button color="secondary" onClick={() => toggleModal('add')}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Edit Modal */}
      <Modal isOpen={editModal} toggle={() => toggleModal('edit')}>
        <ModalHeader toggle={() => toggleModal('edit')}>Edit Sub-IB</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={selectedSubIb?.name || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={selectedSubIb?.email || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="profitPercentage">Payout %</Label>
            <Input
              type="number"
              id="profitPercentage"
              name="profitPercentage"
              value={selectedSubIb?.profitPercentage || 0}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="phone">Phone</Label>
            <InputGroup className="mobile-input-group">
              <PhoneInput
                country={'us'}
                value={selectedSubIb?.phone || ''}
                onChange={phone => setSelectedSubIb({ ...selectedSubIb, phone })}
              />
            </InputGroup>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>Save</Button>
          <Button color="secondary" onClick={() => toggleModal('edit')}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Delete Modal */}
      <Modal isOpen={deleteModal} toggle={() => toggleModal('delete')}>
        <ModalHeader toggle={() => toggleModal('delete')}>Delete Sub-IB</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {selectedSubIb?.name}?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>Delete</Button>
          <Button color="secondary" onClick={() => toggleModal('delete')}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default SubIbPage;
