import React, { Fragment, useState, useEffect } from 'react';
import { MoreHorizontal, Phone } from 'react-feather';
import {
  Card, CardBody, CardHeader, Input, Media, Table, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Pagination, PaginationItem, PaginationLink,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import { Btn, H5, H6, Image, LI, UL } from '../../AbstractElements';
import { Done } from '../../Constant';
import { IoMdPersonAdd } from "react-icons/io";
import { LuRefreshCcw } from "react-icons/lu";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import {
  getStudents,
  getStudentById,
  createStudent,
  updateStudent,
  deleteStudent
} from '../../Services/studentService';
import { getAllCourses } from '../../Services/courseService';
import { getBatches } from '../../Services/batchService';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

const OnGoing = () => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [students, setStudents] = useState([]);
  const [currentStudents, setCurrentStudents] = useState([]);
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [newStudent, setNewStudent] = useState({
    name: '',
    email: '',
    phone: '',
    gender: 'male',
    status: 'active',
    referralCode: '',
    course: '',
    batch: '',
    courseStatus: 'pending',
    profilePicture: ''
  });

  // Fetch students, courses, and batches on component mount
  useEffect(() => {
    fetchStudents();
    fetchCourses();
    fetchBatches();
  }, [currentPage]);

  const fetchStudents = async () => {
    try {
      const data = await getStudents(currentPage, studentsPerPage);
      setTotalPages(data?.totalPages);

      const indexOfLastStudent = currentPage * studentsPerPage;
      const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
      const filter = data?.students.slice(indexOfFirstStudent, indexOfLastStudent);
      setStudents(data?.students);
      setCurrentStudents(filter);

      console.log("setting students", data?.students);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const fetchCourses = async () => {
    try {
      const data = await getAllCourses();
      setCourses(data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchBatches = async () => {
    try {
      const data = await getBatches();
      setBatches(data);
    } catch (error) {
      console.error('Error fetching batches:', error);
    }
  };

  const toggleModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setAddModal(!addModal);
        break;
      case 'edit':
        setEditModal(!editModal);
        break;
      case 'delete':
        setDeleteModal(!deleteModal);
        break;
      default:
        break;
    }
  };

  const handleEditClick = async (studentId) => {
    try {
      const student = await getStudentById(studentId);
      setSelectedStudent(student);
      setEditModal(true);
    } catch (error) {
      console.error('Error fetching student:', error);
    }
  };

  const handleDeleteClick = async (studentId) => {
    try {
      const student = await getStudentById(studentId);
      setSelectedStudent(student);
      setDeleteModal(true);
    } catch (error) {
      console.error('Error fetching student:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedStudent({ ...selectedStudent, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedStudent({ ...selectedStudent, profilePicture: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewStudent({ ...newStudent, [name]: value });
  };

  const handleNewFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewStudent({ ...newStudent, profilePicture: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      if (selectedStudent._id) {
        await updateStudent(selectedStudent._id, selectedStudent);
        toast.success("Updated Student Succesfully");
      } else {
        await createStudent(selectedStudent);
        toast.success("Created Student Succesfully");
      }
      fetchStudents();
      toggleModal('edit');
    } catch (error) {
      console.error('Error saving student:', error);
      toast.error('Error saving student:', error);
    }
  };

  const handleCreate = async () => {
    try {
      await createStudent(newStudent);
      fetchStudents();
      toggleModal('add');
      setNewStudent({
        name: '',
        email: '',
        phone: '',
        gender: 'male',
        status: 'active',
        referralCode: '',
        course: '',
        batch: '',
        courseStatus: 'pending',
        profilePicture: '',
        paymentId: ''
      });
      toast.success("Student Added");
    } catch (error) {
      toast.error("Failed to Create student " + error.message);
      console.error('Error creating student:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteStudent(selectedStudent._id);
      fetchStudents();
      toggleModal('delete');
      toast.success("Student Deleted Successfully");
    } catch (error) {
      console.error('Error deleting student:', error);
      toast.error('Error deleting student:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilter = (e) => {
    setFilterStatus(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchStudents();
  };
  const [inputPage, setInputPage] = useState('');

  const handlepageInput = (e) => {
    setInputPage(e.target.value);
  };

  const handleInputSubmit = (e) => {
    if (e.key === 'Enter') {
      const pageNum = Number(inputPage);
      if (pageNum > totalPages) {
        toast.error("Page not Found")
      }
      if (pageNum > 0 && pageNum <= totalPages) {
        handlePageChange(pageNum);
      }
    }
  };

  const renderPageNumbers = () => {
    const pagesToShow = 10;
    let startPage = currentPage <= pagesToShow ? 1 : currentPage - 5;
    let endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationItem key={i} active={currentPage === i} style={{ marginTop: '5px' }}>
          <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return pageNumbers;
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(students.map(student => ({
      Name: student.name,
      Email: student.email,
      Phone: student.phone,
      Gender: student.gender,
      Status: student.status,
      ReferralCode: student.referralCode,
      paymentId: student.paymentId,
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Students');
    XLSX.writeFile(wb, 'students.xlsx');
    toast.success("Student data Exported");
  };

  // Search and Filter Logic
  const filteredStudents = students
    .filter(student =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.status.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(student =>
      filterStatus === '' || student.status.toLowerCase() === filterStatus.toLowerCase()
    );

  // Pagination Logic

  // const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);
  console.log("total pages", totalPages, "filtered Student", filteredStudents.length)

  return (
    <Fragment>
      <Card style={{ width: '100%' }}>
        <CardHeader>
          <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center">
            <H5>{Done}</H5>
            <div className="d-flex flex-wrap align-items-center">
              <div className='d-flex flex-wrap gap-2' style={{ marginRight: '10px' }}>
                <div className="search-box mb-2 mb-md-0 mr-2 mr-md-3" >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
                <div className="filter-box mb-2 mb-md-0 mr-2 mr-md-3 ">
                  <select
                    className="form-control"
                    value={filterStatus}
                    onChange={handleFilter}
                  >
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>
              <div className='d-flex flex-wrap gap-2'>
                <Btn
                  attrBtn={{
                    color: 'primary',
                    className: 'badge-light d-block btn-mail',
                    onClick: fetchStudents
                  }}
                >
                  <LuRefreshCcw />
                </Btn>
                <Button color="primary" onClick={exportToExcel}>Export</Button>
                <Btn
                  attrBtn={{
                    color: 'primary',
                    className: 'badge-light d-block btn-mail',
                    onClick: () => toggleModal('add')
                  }}
                >
                  <IoMdPersonAdd />
                </Btn>
              </div>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <Table responsive className="table-responsive">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Gender</th>
                <th scope="col">Status</th>
                <th scope="col">Referral Code</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student) => (
                <tr key={student._id}>
                  <td>{student.name}</td>
                  <td>{student.email}</td>
                  <td>{student.phone}</td>
                  <td>{student.gender}</td>
                  <td>{student.status}</td>
                  <td>{student.referralCode}</td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() => handleEditClick(student._id)}
                    >
                      Edit
                    </Button>{' '}
                    <Button
                      color="danger"
                      onClick={() => handleDeleteClick(student._id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example" >
              <PaginationItem disabled={currentPage === 1} style={{ marginTop: '5px' }}>
                <PaginationLink first onClick={() => handlePageChange(1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === 1} style={{ marginTop: '5px' }}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
              </PaginationItem>

              {renderPageNumbers()}

              {/* Show input box when total pages exceed 10 */}
              {totalPages > 10 && (
                <Input
                  type="number"
                  min="1"
                  max={totalPages}
                  value={inputPage}
                  onChange={handlepageInput}
                  onKeyPress={handleInputSubmit}
                  placeholder="Go to page"
                  style={{ width: '70px', height: '30px', display: 'inline-block', marginLeft: '10px', marginTop: '5px', marginRight: '10px' }}
                />
              )}

              <PaginationItem disabled={currentPage === totalPages} style={{ marginTop: '5px' }}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages} style={{ marginTop: '5px' }}>
                <PaginationLink last onClick={() => handlePageChange(totalPages)} />
              </PaginationItem>
            </Pagination>
          </div>
        </CardBody>
      </Card>

      {/* Create Modal */}
      <Modal isOpen={addModal} toggle={() => toggleModal('add')}>
        <ModalHeader toggle={() => toggleModal('add')}>Add New Student</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={newStudent.name}
              onChange={handleNewInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={newStudent.email}
              onChange={handleNewInputChange}
            />
          </FormGroup>
          {/* <FormGroup>
            <Label for="phone">Phone</Label>
            <Input
              type="text"
              id="phone"
              name="phone"
              value={newStudent.phone}
              onChange={handleNewInputChange}
            />
          </FormGroup> */}
          <FormGroup>
            <Label for="phone">Phone</Label>
            <InputGroup className="mobile-input-group">
              <div className="phone-input-container" style={{ display: 'flex', width: '100%' }}>
                <PhoneInput
                  country={'us'}
                  value={newStudent.phone}
                  onChange={phone => setNewStudent({ ...newStudent, phone })}
                  inputStyle={{
                    width: '100%',
                    borderRadius: '0 0.375rem 0.375rem 0',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                    paddingLeft: '40px', // Adjusted padding for better alignment
                    marginLeft: '8px',   // Added margin to create gap between dropdown and input
                    flexGrow: 1          // Ensures the input takes up the full remaining width
                  }}
                  buttonStyle={{
                    borderRadius: '0.375rem 0 0 0.375rem',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                  }}
                  containerClass="phone-input-container"
                />
              </div>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="paymentId">paymentId</Label>
            <Input
              type="text"
              id="paymentId"
              name="paymentId"
              value={selectedStudent?.paymentId || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              type="text"
              id="password"
              name="password"
              value={newStudent.password}
              onChange={handleNewInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="gender">Gender</Label>
            <Input
              type="select"
              id="gender"
              name="gender"
              value={newStudent.gender}
              onChange={handleNewInputChange}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="status">Status</Label>
            <Input
              type="select"
              id="status"
              name="status"
              value={newStudent.status}
              onChange={handleNewInputChange}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="course">Course</Label>
            <Input
              type="select"
              id="course"
              name="course"
              value={newStudent.course}
              onChange={handleNewInputChange}
            >
              <option value="">Select Course</option>
              {courses.map((course) => (
                <option key={course._id} value={course._id}>{course.name}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="batch">Batch</Label>
            <Input
              type="select"
              id="batch"
              name="batch"
              value={newStudent.batch._id}
              onChange={handleNewInputChange}
            >
              <option value="">Select Batch</option>
              {batches.map((batch) => (
                <option key={batch._id} value={batch._id}>{batch.batchName}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="courseStatus">Course Status</Label>
            <Input
              type="select"
              id="courseStatus"
              name="courseStatus"
              value={newStudent.courseStatus}
              onChange={handleNewInputChange}
            >
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="cancelled">Cancelled</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="profilePicture">Profile Picture</Label>
            <Input
              type="file"
              id="profilePicture"
              name="profilePicture"
              onChange={handleNewFileChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCreate}>Create</Button>
          <Button color="secondary" onClick={() => toggleModal('add')}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Edit Modal */}
      <Modal isOpen={editModal} toggle={() => toggleModal('edit')}>
        <ModalHeader toggle={() => toggleModal('edit')}>Edit Student</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={selectedStudent?.name || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={selectedStudent?.email || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          {/* <FormGroup>
            <Label for="phone">Phone</Label>
            <Input
              type="text"
              id="phone"
              name="phone"
              value={selectedStudent?.phone || ''}
              onChange={handleInputChange}
            />
          </FormGroup> */}
          <FormGroup>
            <Label for="phone">Phone</Label>
            <InputGroup className="mobile-input-group">
              <div className="phone-input-container" style={{ display: 'flex', width: '100%' }}>
                <PhoneInput
                  country={'us'}
                  value={selectedStudent?.phone || ''}
                  onChange={phone => setSelectedStudent({ ...selectedStudent, phone })}
                  inputStyle={{
                    width: '100%',
                    borderRadius: '0 0.375rem 0.375rem 0',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                    paddingLeft: '40px', // Adjusted padding for better alignment
                    marginLeft: '8px',   // Added margin to create gap between dropdown and input
                    flexGrow: 1          // Ensures the input takes up the full remaining width
                  }}
                  buttonStyle={{
                    borderRadius: '0.375rem 0 0 0.375rem',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                  }}
                  containerClass="phone-input-container"
                />
              </div>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="">Payment ID</Label>
            <Input
              type="text"
              id="paymentId"
              name="paymentId"
              value={selectedStudent?.paymentId || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="gender">Gender</Label>
            <Input
              type="select"
              id="gender"
              name="gender"
              value={selectedStudent?.gender || ''}
              onChange={handleInputChange}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="status">Status</Label>
            <Input
              type="select"
              id="status"
              name="status"
              value={selectedStudent?.status || ''}
              onChange={handleInputChange}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="referralCode">Referral code</Label>
            <Input
              type="text"
              id="referralCode"
              name="referralCode"
              value={selectedStudent?.referralCode || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="course">Course</Label>
            <Input
              type="select"
              id="course"
              name="course"
              value={selectedStudent?.course || ''}
              onChange={handleInputChange}
            >
              <option value="">Select Course</option>
              {courses.map((course) => (
                <option key={course._id} value={course._id}>{course.name}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="batch">Batch</Label>
            <Input
              type="select"
              id="batch"
              name="batch"
              value={selectedStudent?.batch || ''}
              onChange={handleInputChange}
            >
              <option value="">Select Batch</option>
              {batches.map((batch) => (
                <option key={batch._id} value={batch._id}>{batch.batchName}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="courseStatus">Course Status</Label>
            <Input
              type="select"
              id="courseStatus"
              name="courseStatus"
              value={selectedStudent?.courseStatus || ''}
              onChange={handleInputChange}
            >
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="cancelled">Cancelled</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="profilePicture">Profile Picture</Label>
            <Input
              type="file"
              id="profilePicture"
              name="profilePicture"
              onChange={handleFileChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>Save</Button>
          <Button color="secondary" onClick={() => toggleModal('edit')}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Delete Modal */}
      <Modal isOpen={deleteModal} toggle={() => toggleModal('delete')}>
        <ModalHeader toggle={() => toggleModal('delete')}>Delete Student</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {selectedStudent?.name}?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>Delete</Button>
          <Button color="secondary" onClick={() => toggleModal('delete')}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default OnGoing;
