import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCourseById } from '../../Services/courseService';
import { createCoursePaymentOrder } from '../../Services/paymentService';
import { Btn } from '../../AbstractElements';
import PhoneInput from 'react-phone-input-2';
import './CoursePage.css'; // Ensure this file contains updated styles for a professional look

const PurchaseCoursePage = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', referralCode: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formStatus, setFormStatus] = useState('loading');

  useEffect(() => {
    getCourseById(id)
      .then((response) => {
        setCourse(response);
        setFormStatus('valid');
      })
      .catch((error) => {
        console.error('Error fetching course:', error);
        setFormStatus('error');
      });
  }, [id]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.phone || !formData.name) {
      alert('Name, Email, and Phone are required.');
      return;
    }

    setIsSubmitting(true);
    try {
      const paymentData = {
        course: id,
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        referralCode: formData.referralCode,
        MUID: `MUID_${Date.now()}`,
        transactionId: `T${Date.now()}`
      };

      const response = await createCoursePaymentOrder(paymentData);
      if (response?.data?.instrumentResponse?.redirectInfo?.url) {
        window.location.href = response.data.instrumentResponse.redirectInfo.url;
      } else {
        alert('Payment failed: No redirect URL found');
      }
    } catch (error) {
      console.error('Payment error:', error);
      alert('Payment error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (formStatus === 'loading') {
    return <div className="loading-screen">Loading...</div>;
  }

  if (formStatus === 'error') {
    return <div className="error-message">Failed to load course information.</div>;
  }

  return (
    <div className="course-page-container">
      {course && (
        <div className="course-page-content">
          {/* Header section with background image */}
          <div className="header-section">

            <h1 className="course-title">{course.name}</h1>
            <p className="course-description">{course.description}</p>
            <div className="course-details">
              <p><strong>Duration:</strong> {course.duration} hours</p>
              <p><strong>Level:</strong> {course.level}</p>
              <p><strong>Price:</strong> ₹{course.price}</p>
              <p><strong>Instructor:</strong> {course.instructor}</p>
              <p><strong>Start Date:</strong> {new Date(course.startDate).toDateString()}</p>
            </div>
          </div>

          <div className="syllabus-section">
            <h3>Syllabus</h3>
            <ul>
              {course.syllabus.map((topic, index) => (
                <li key={index} className="syllabus-item">{topic}</li>
              ))}
            </ul>
          </div>

          <form className="purchase-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Full Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter your full name"
                required
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="form-group">
              <label>Phone</label>
              <PhoneInput
                country={'us'}
                value={formData.phone}
                onChange={handlePhoneChange}
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: false
                }}
                inputStyle={{ width: '100%' }}
                containerStyle={{ width: '100%' }}
              />
            </div>
            <div className="form-group">
              <label>Reference  Name</label>
              <input
                type="text"
                name="referralCode"
                value={formData.referralCode}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter your Referal"
              // required
              />
            </div>

            <div className="form-actions">
              <Btn attrBtn={{ color: 'primary', type: 'submit', disabled: isSubmitting }}>
                {isSubmitting ? 'Submitting...' : `Pay ₹${course.price}`}
              </Btn>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default PurchaseCoursePage;
