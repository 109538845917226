import React from 'react'
import OnGoing from './OnGoing';

function PurchaseCoursePage() {
  const val = true;
  return (
    <>
      <OnGoing val={val} />
    </>
  )
}

export default PurchaseCoursePage;